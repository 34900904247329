import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout/Layout";
import ItemCard from "../components/Shop/ItemCard";
import {
	TextField,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import styles from "./shop.module.css";

export default function Shop() {
	const { allDataJson } = useStaticQuery(
		graphql`
			{
				allDataJson {
					edges {
						node {
							collections
							items {
								name
								price
								specs
								collection
								description
								colorways {
									name
									path {
										id
										childImageSharp {
											fluid(
												maxHeight: 1000
												maxWidth: 1000
											) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const data = allDataJson.edges[0].node;

	const [collectionKey, setCollectionKey] = useState();

	const imgStyles = {
		width: "95%",
		height: "400px",
		objectFit: "cover",
		margin: "0px auto",
	};

	const cardMapper = (item, i) => (
		<ItemCard item={item} key={`${item}-${i}`}>
			<Img
				fluid={item.colorways[0].path.childImageSharp.fluid}
				style={imgStyles}
			/>
		</ItemCard>
	);

	useEffect(() => {
		if (window) {
			const search = decodeURI(window.location.search.substring(1));
			if (data.collections.includes(search)) {
				setCollectionKey(search);
			}
		}
	}, []);
	return (
		<Layout>
			<Helmet>
				<title>Shop | Circa Wallcoverings</title>
				<meta name="Description" content="Shop our collections" />
			</Helmet>
			<div className={styles.gridHeader}>
				<h3>Show collection:</h3>
				<Select
					id="collection-all"
					style={{ marginLeft: "15px", minWidth: "75px" }}
					onChange={(e) => setCollectionKey(e.target.value)}
				>
					<MenuItem value={undefined} key={`collection-all`}>
						All
					</MenuItem>
					{data.collections.map((collectionName, i) => (
						<MenuItem
							value={collectionName}
							key={`collection-${i}`}
						>
							{collectionName}
						</MenuItem>
					))}
				</Select>
			</div>
			<div className={styles.gridContainer}>
				<Grid container spacing={4} justify="space-around">
					{collectionKey
						? data.items
								.filter(
									({ collection }) =>
										collection === collectionKey
								)
								.map(cardMapper)
						: data.items.map(cardMapper)}
				</Grid>
			</div>
		</Layout>
	);
}
