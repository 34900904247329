import React from 'react';
import { Link } from 'gatsby';
import Paper from '../global/Paper';
import { Grid } from '@material-ui/core';
import styles from './ItemCard.module.css';
import slug from 'slug';

export default function ItemCard(props){
    const { item, children } = props;
    const {
        name,
        collection
    } = item;
    const path = slug(name);
    return(
        <Grid item xs={10} sm={6} md={4} lg={4}>
            <Link to={`/shop/${path}`}>
                <Paper width={'inherit'} height={'auto'} 
               paddingTop={'10px'} paddingBottom={'10px'}>
                    {children}
                    <div style={{width:'100%', height: '10px'}}/>
                    <div className={styles.infoContainer}>
                        <h2 className={styles.title}>{name}</h2>
                        <h3 className={styles.collection}>{collection}</h3>
                    </div>
                </Paper>
            </Link>
        </Grid>
    )
}